import React from 'react'
import SimpleLayout from '../components/Layouts/Simple.js'
import CustomerForm from '../components/Customers/Form.js'
import Head from '../components/Common/Head'
import { Link } from 'react-router-dom'

const SignUp = () => {
  return (
    <SimpleLayout>
      <Head title='Registro' />
      <div className="narrow-form p-2">
        <CustomerForm />

        <div className='text-center my-2' style={{ fontSize: '25px' }}>
          <span className='mr-2'>¿Yá tienes una cuenta?</span>
          <Link to='/login'>Inicia Sesión</Link>
        </div>
      </div>
    </SimpleLayout>
  )
}

export default SignUp
