import React from 'react';
import { connect } from 'react-redux';
import ApplicationLayout from '../components/Layouts/Application.js';
import ProductList from '../components/Products/List.js';
import Sidebar from '../components/Layouts/Sidebar.js';
import Head from '../components/Common/Head';
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';
import { Link } from 'react-router-dom';

function Home(props) {
  props.dispatch({ type: 'setDefaultPlace' });

  return (
    <ApplicationLayout>
      <Head title='APP' />
      <div className="home-grid">
        <Sidebar />
        <ProductList />
      </div>
      {props.company.active_reservations &&
        <Link className="fab-action" to='reservation'>
          <Fab icon={<MaterialIcon icon='deck' />} title="Hacer una Reserva" textLabel="Reservar" />
        </Link>}
    </ApplicationLayout>
  );
}

const mapStateToProps = (state) => ({
  company: state.company
});
export default connect(mapStateToProps)(Home);
