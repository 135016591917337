import React from 'react';
import { connect } from 'react-redux';
import {
  Body2
} from '@material/react-typography';
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';
import { Link } from 'react-router-dom';
import { convertToCurrency } from '../../filters/currency';
import { calculateDeliveryCost, formatDeliveryTime } from '../../utils/util';
import jQuery from 'jquery';
require('popper.js');
require('bootstrap');

class CartBar extends React.Component {
  componentDidMount() {
    jQuery('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
  }

  render() {
    return (
      <div className={`checkout-bar ${this.props.className !== undefined ? this.props.className : ''}`}>
        <div>
          <Body2 className="my-1">Orden Mínima</Body2>
          <Body2 className="my-0">{convertToCurrency(this.props.company.minimum_order_value)}</Body2>
        </div>
        <div>
          <Body2 className="my-1">Costo de Envío</Body2>
          <Body2 className="my-0">{calculateDeliveryCost(this.props.company)}</Body2>
        </div>
        <div>
          <Body2 className="my-1">Entrega en</Body2>
          <Body2 className="my-0">{formatDeliveryTime(this.props.company)}</Body2>
        </div>
        <div className="d-none d-sm-inline">
          <Link to="/cart" title="Ver Carrito"><Fab icon={<MaterialIcon icon="shopping_cart"/>} textLabel={this.props.countSelectedProducts > 0 ? this.props.countSelectedProducts : ''} /></Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countSelectedProducts: state.countSelectedProducts,
  company: state.company
});
export default connect(mapStateToProps)(CartBar);
