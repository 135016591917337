import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import MaterialIcon from '@material/react-material-icon'
import Button from '@material/react-button'
import SimpleLayout from '../components/Layouts/Simple.js'
import { Headline4 } from '@material/react-typography'
import { Link } from 'react-router-dom'
import Auth from '../utils/auth'
import Head from '../components/Common/Head'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const useStyles = makeStyles((theme) => ({
  leadingIcon: {
    color: 'rgba(0,0,0,0.54)'
  },
}))

const LogIn = (props) => {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const redirectTo = useCallback(() => {
    return props.location.state && props.location.state.from ? props.location.state.from.pathname : '/'
  }, [props.location])

  useEffect(() => {
    if (Auth.isAuthenticated(props)) {
      props.history.push({ pathname: redirectTo() })
    }

    // eslint-disable-next-line
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    props.dispatch({ type: 'showPreLoader' })
    Auth.login(props, { email: email, password: password }, redirectTo())
  }

  return (
    <SimpleLayout>
      <Head title='Inicio de Sesión' />
      <div className='narrow-form p-2'>
        <Headline4 className='my-2'>Inicio de Sesión</Headline4>

        <Card className='py-2 px-1 mb-1'>
          <form onSubmit={handleSubmit}>
            <div className='mb-1'>
              <TextField
                id='email'
                className='w-100 mb-3'
                type='email'
                variant='outlined'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                inputProps={{
                  maxLength: 180,
                  'aria-label': 'Correo Electrónico'
                }}
                label='Correo Electrónico'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MaterialIcon className={classes.leadingIcon} icon='email' />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id='password'
                className='w-100 mb-3'
                type='password'
                variant='outlined'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                inputProps={{
                  'aria-label': 'Contraseña'
                }}
                label='Contraseña'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MaterialIcon className={classes.leadingIcon} icon='vpn_key' />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button raised className='w-100' type='submit'>Iniciar Sesión</Button>
          </form>
        </Card>

        <div className='text-center my-2' style={{ fontSize: '25px' }}>
          <span className='mr-2'>¿No tienes una cuenta?</span>
          <Link to='/sign_up'>Registrate</Link>
        </div>
      </div>
    </SimpleLayout>
  )
}

const mapStateToProps = (state) => ({
  $http: state.$http,
  companyId: state.companyId
})
export default connect(mapStateToProps)(withRouter(LogIn))
