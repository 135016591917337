import React from 'react'
import { connect } from 'react-redux'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const createTheme = ({ primary_color = null, secondary_color = null }) => createMuiTheme({
  palette: {
    primary: {
      main: primary_color || '#ffc107'
    },
    secondary: {
      main: secondary_color || '#ff5722'
    },
  },
})

const MuiTheme = (props) => {
  return (
    <ThemeProvider theme={createTheme(props.company)}>
      {props.children}
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  company: state.company
})
export default connect(mapStateToProps)(MuiTheme)
