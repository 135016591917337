import UOrderApi from '../services/uorder-api'

const CUSTOMER_KEY = 'uorderCustomer'

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

function getCustomerInfo(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(b64DecodeUnicode(base64))
}

const Auth = {
  setCurrentCustomer(context, customer) {
    context.props.dispatch({ type: 'updateCustomer', customer: customer })
  },

  login(context, credentials, redirect = null) {
    UOrderApi.customers.login(context.$http, credentials)
      .then((response) => {
        this.authenticateCustomer(context, response.token)

        context.dispatch({ type: 'hidePreLoader' })
        if (redirect) {
          context.history.push(redirect)
          context.dispatch({ type: 'hidePreLoader' })
        }

        context.dispatch({ type: 'loadLastAddress' })
      }).catch((error) => {
        console.error('Error logging in as customer: ', error)
        context.dispatch({ type: 'hidePreLoader' })
        context.dispatch({ type: 'openSnackbar', snackbar: { message: 'No se ha podido autenticar el usuario.', type: 'error' } })
      })
  },

  authenticateCustomer(context, jwt) {
    if (jwt) {
      const authenticatedHttp = context.$http

      localStorage.setItem(CUSTOMER_KEY, jwt)

      context.dispatch({ type: 'updateSignedCustomer', signedCustomer: Object.assign(jwt, { authenticated: true }) })

      authenticatedHttp.defaults.headers.Authorization = this.getAuthToken()
      context.dispatch({ type: 'updateHttpConnector', $http: authenticatedHttp })
    } else {
      this.logoutWithoutRedirection(context)
    }
  },

  logout(context) {
    this.logoutWithoutRedirection(context)
    context.history.push('/')
  },

  logoutWithoutRedirection(context) {
    UOrderApi.customers.logout(context.$http)
      .then((response) => {
        context.dispatch({ type: 'hidePreLoader' })
      }).catch((error) => {
        console.error('Error loggin out as customer: ', error)
        context.dispatch({ type: 'hidePreLoader' })
      })

    localStorage.removeItem(CUSTOMER_KEY)
    context.dispatch({ type: 'updateSignedCustomer', signedCustomer: { authenticated: false } })
    context.dispatch({ type: 'setAddress', selectedAddress: {} })
    context.dispatch({ type: 'openSnackbar', snackbar: { message: 'Gracias, vuelve pronto!' } })

    if (context.$http) {
      try {
        const noAuthenticatedHttp = context.$http
        delete noAuthenticatedHttp.defaults.headers.Authorization
        context.dispatch({ type: 'updateHttpConnector', $http: noAuthenticatedHttp })
      } catch (error) {
        console.error('Error removing authorization: ', error)
      }
    }
  },

  isAuthenticated(store) {
    const jwt = localStorage.getItem(CUSTOMER_KEY)

    if (jwt) {
      store.dispatch({ type: 'updateSignedCustomer', signedCustomer: Object.assign(getCustomerInfo(jwt), { authenticated: true }) })
      return true
    } else if (store.$http) {
      const noAuthenticatedHttp = store.$http
      delete noAuthenticatedHttp.defaults.headers.Authorization
      store.dispatch({ type: 'updateHttpConnector', $http: noAuthenticatedHttp })
    }

    return false
  },

  getAuthToken() {
    const token = localStorage.getItem(CUSTOMER_KEY)

    if (token) {
      return `Bearer ${token}`
    }

    return null
  }
}

export default Auth
