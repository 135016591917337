import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/navbar.css';
import '../../styles/toolbar.css';
import '@material/react-top-app-bar/index.scss';
import TopAppBar, {
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import CheckoutBar from '../Checkout/Bar';
import { Headline4 } from '@material/react-typography';
import CategorySelect from '../Categories/Select';
import { Link } from 'react-router-dom';
import { MDCRipple } from '@material/ripple';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.searchButton = React.createRef();
    this.state = {
      value: this.props.searchTerm
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    new MDCRipple(document.querySelector('.mdc-button.cart'));
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  reset() {
    this.setState({value: ''});
    this.props.dispatch({ type: 'searchProduct', searchTerm: '' });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.searchInput) {
      this.searchInput.current.blur();
    }
    if (this.searchButton) {
      this.searchButton.current.focus();
    }
    this.props.dispatch({ type: 'searchProduct', searchTerm: this.state.value });
  }

  resetButton() {
    if (this.state.value && this.state.value !== '') {
      return (<div className="input-group-prepend">
        <button className="btn btn-sm btn-light" type="button" onClick={this.reset}><MaterialIcon role="button" icon="highlight_off"/></button>
      </div>)
    }

    return null
  }

  openDrawer() {
    this.props.dispatch({ type: 'openDrawer' });
  }

  render() {
    return (
      <TopAppBar fixed={true} className="nav-complex">
        <div className="mdc-layout-grid mx-md-1 mx-lg-auto my-1 d-none d-md-block">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1-phone mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-1-desktop d-flex align-items-center">
              <TopAppBarIcon navIcon tabIndex={0}>
                <MaterialIcon hasRipple icon='menu' onClick={(e) => this.openDrawer(e)}/>
              </TopAppBarIcon>
            </div>
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-phone mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-desktop text-center d-flex flex-column justify-content-center align-items-center">
              <img src={this.props.company.logo_url} alt="" style={{ maxWidth: '120px', maxHeight: '100px' }} />
              <Headline4 className="my-1" style={{ wordWrap: 'break-word' }}>{this.props.title}</Headline4>
            </div>
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-7-desktop d-flex flex-column align-items-center">
              <form className="w-100 pr-4" onSubmit={this.handleSubmit}>
                <div className="input-group mb-3">
                  {this.resetButton()}
                  <input type="text" className="form-control" placeholder="Buscar" aria-label="Buscar" aria-describedby="search-addon" value={this.state.value} onChange={this.handleChange} ref={this.searchInput} />
                  <div className="input-group-append">
                    <button className="btn btn-light" type="submit" id="search-addon" ref={this.searchButton}><MaterialIcon role="button" icon="search"/></button>
                  </div>
                </div>
              </form>
              <CheckoutBar />
            </div>
          </div>
        </div>

        <div className="d-block d-md-none">
          <TopAppBarRow>
            <TopAppBarSection align='start' className="justify-content-between pb-0">
              <TopAppBarIcon navIcon tabIndex={0}>
                <MaterialIcon hasRipple icon='menu' onClick={(e) => this.openDrawer(e)}/>
              </TopAppBarIcon>
              <form className="w-100" onSubmit={this.handleSubmit}>
                <div className="input-group mb-3 w-100">
                  {this.resetButton()}
                  <input type="text" className="form-control form-control-sm" placeholder="Buscar" aria-label="Buscar" aria-describedby="search-addon2" value={this.state.value} onChange={this.handleChange} required="required" />
                  <div className="input-group-append">
                    <button className="btn btn-sm btn-light" type="submit" id="search-addon2"><MaterialIcon role="button" icon="search"/></button>
                  </div>
                </div>
              </form>
            </TopAppBarSection>
            <TopAppBarSection className="justify-content-around pt-0">
              <img src={this.props.company.logo_url} className="mr-2 mr-md-0" alt="" style={{ maxWidth: '120px', maxHeight: '100px' }} />
              <Headline4 style={{ wordWrap: 'break-word' }}>{this.props.title}</Headline4>
            </TopAppBarSection>
            <TopAppBarSection role='toolbar' className="d-none d-sm-inline-flex justify-content-center">
              <CheckoutBar />
            </TopAppBarSection>
          </TopAppBarRow>
          <div className="checkout-smallbar d-sm-none d-flex justify-content-center">
            <CheckoutBar />
          </div>
          <div className="d-sm-none">
            <div className="category-menu-bar">
              <CategorySelect className="align-self-start" />
              <Link to='/cart' onClick={this.handleClick} title="Ver Carrito" className="mdc-button mdc-button--unelevated cart">
                <MaterialIcon icon="shopping_cart"/>&nbsp;{this.props.countSelectedProducts > 0 ? this.props.countSelectedProducts : ''}
              </Link>
            </div>
          </div>
        </div>
      </TopAppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  searchTerm: state.searchTerm,
  title: state.companyName,
  countSelectedProducts: state.countSelectedProducts,
  company: state.company
});
export default connect(mapStateToProps)(withRouter(NavBar));
