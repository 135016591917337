import React from 'react';
import { connect } from 'react-redux';
import "@material/select/mdc-select.scss";
import { MDCSelect } from '@material/select';

class CategorySelect extends React.Component {
  componentDidMount() {
    new MDCSelect(document.querySelector('.mdc-select.categories'));
  }

  selectCategory(index) {
    this.props.dispatch({ type: 'showPreLoader' });
    this.props.dispatch({ type: 'selectCategory', categoryIndex: index });
  }

  render() {
    return (
      <div className={`mdc-select mdc-select--outlined categories mt-1 ${this.props.className !== undefined ? this.props.className : ''}`}>
        <i className="mdc-select__dropdown-icon"></i>
        <select className="mdc-select__native-control text-uppercase" value={this.props.selectedCategory} onChange={(e) => this.selectCategory(e.target.value)}>
          {
            this.props.categories.map((category, i) => {
              return <option key={`${i}-${category.id}`} value={i}>{category.name}</option>
            })
          }
        </select>
        <label className="mdc-floating-label"></label>
        <div className="mdc-line-ripple"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  selectedCategory: state.selectedCategory
});
export default connect(mapStateToProps)(CategorySelect);
