import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TextField, { HelperText, Input } from '@material/react-text-field'
import Checkbox from '@material/react-checkbox'
import MaterialIcon from '@material/react-material-icon'
import Button from '@material/react-button'
import { Headline4 } from '@material/react-typography'

import Card from '@material/react-card'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers'

import { extractErrors } from '../../utils/util'
import UOrderApi from '../../services/uorder-api.js'
import Auth from '../../utils/auth.js'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import 'moment/locale/es'
moment.locale('es')

const CustomerForm = function (props) {
  const birthdate = moment().subtract(18, 'years').format('YYYY-MM-DD')

  const [customer, setCustomer] = useState(Object.assign({}, {
    first_name: '',
    last_name: '',
    birthdate: birthdate,
    mobile: '',
    email: '',
    password: '',
    confirmation_password: '',
    accepted_terms_and_conditions: false
  }, props.customer
  ))

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setCustomer(Object.assign({}, customer, { [name]: value }))
  }

  const registerCustomer = (customer) => {
    UOrderApi.customers.create(props.$http, customer)
      .then((response) => {
        Auth.authenticateCustomer(props, response.token)

        props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Te has registrado exitosamente!, por favor agrega tu dirección.' } })
        props.history.push('/address/new')
        props.dispatch({ type: 'hidePreLoader' })
      })
      .catch((error) => {
        props.dispatch({ type: 'hidePreLoader' })
        props.dispatch({ type: 'openSnackbar', snackbar: { message: extractErrors(error, 'No se han podido registrar tus datos. Por favor intenta más tarde.'), type: 'error' } })
        console.error('Error creating customer: ', error)
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (customer.password !== customer.confirmation_password) {
      props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Las contraseñas no coinciden!', type: 'error' } })
    } else if (!customer.accepted_terms_and_conditions) {
      props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Debes aceptar los terminos y condiciones del servicio.', type: 'error' } })
    } else {
      const preparedCustomer = {
        details_attributes: {
          first_name: customer.first_name,
          last_name: customer.last_name,
          birthdate: customer.birthdate,
          mobile: customer.mobile,
          accepted_terms_and_conditions: customer.accepted_terms_and_conditions
        },
        email: customer.email,
        password: customer.password,
        confirmation_password: customer.confirmation_password,
        company_id: props.companyId
      }

      registerCustomer(preparedCustomer)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Headline4 className='my-2'>Nuevo Registro</Headline4>

      <Card outlined className='p-1 mb-1'>
        <TextField
          outlined
          label='Nombres'
          className='w-100 mt-2 mb-3'
        ><Input
            name='first_name'
            value={customer.first_name}
            onChange={handleInputChange}
            required={true}
          />
        </TextField>

        <TextField
          outlined
          label='Apellidos'
          className='w-100 mb-3'
        ><Input
            name='last_name'
            value={customer.last_name}
            onChange={handleInputChange}
            required={true}
          />
        </TextField>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            disableFuture
            inputVariant='outlined'
            format='DD/MM/yyyy'
            margin='none'
            id='birthdate'
            name='birthdate'
            label='Fecha de Nacimiento'
            value={customer.birthdate || birthdate}
            views={['year', 'month', 'date']}
            maxDate={birthdate}
            onChange={(e) => setCustomer(Object.assign({}, customer, { birthdate: e.format('YYYY-MM-DD') }))}
            className='mb-3'
            InputProps={{
              startAdornment: <InputAdornment position='start'><MaterialIcon icon='event' style={{ color: 'rgba(0,0,0,0.54)' }} /></InputAdornment>
            }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          outlined
          label='Celular'
          className='w-100 mb-3'
        ><Input
            type='tel'
            name='mobile'
            value={customer.mobile}
            onChange={handleInputChange}
            required={true}
            minLength={10}
            maxLength={10}
          />
        </TextField>

        <TextField
          outlined
          label='Email'
          className='w-100 mb-3'
          leadingIcon={<MaterialIcon icon='email' />}
        ><Input
            type='email'
            name='email'
            value={customer.email}
            onChange={handleInputChange}
            required={true}
          />
        </TextField>

        <TextField
          outlined
          label='Contraseña'
          className='w-100'
          helperText={<HelperText className='mb-2'>Debe contener al menos 8 digitos</HelperText>}
          leadingIcon={<MaterialIcon icon='vpn_key' />}
        ><Input
            type='password'
            name='password'
            value={customer.password}
            onChange={handleInputChange}
            required={true}
            minLength={8}
          />
        </TextField>

        <TextField
          outlined
          label='Confirmación de Contraseña'
          className='w-100'
          helperText={<HelperText>Debe contener al menos 8 digitos</HelperText>}
          leadingIcon={<MaterialIcon icon='vpn_key' />}
        ><Input
            type='password'
            name='confirmation_password'
            value={customer.confirmation_password}
            onChange={handleInputChange}
            required={true}
            minLength={8}
          />
        </TextField>

        <div className='d-flex align-items-center'>
          <Checkbox
            nativeControlId='terms-and-conditions'
            checked={customer.accepted_terms_and_conditions}
            onChange={(e) => setCustomer(Object.assign({}, customer, { accepted_terms_and_conditions: e.target.checked }))}
          />
          <label htmlFor='terms-and-conditions' style={{ cursor: 'pointer' }}>Acepto los Terminos y Condiciones</label>
        </div>

        <Button raised className='w-100'><MaterialIcon icon='how_to_reg' className='mr-1' />Registrarme</Button>
      </Card>
    </form>
  )
}

const mapStateToProps = (state) => ({
  $http: state.$http,
  companyId: state.companyId
})
export default connect(mapStateToProps)(withRouter(CustomerForm))
